import { v4 as uuidv4 } from "uuid";
function chillHop() {
	return [
		{
			name: "01. Love In The Air",
			cover:
				"/asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/01.Love In The Air.wav",
			color: ["#205950", "#2ab3bf"],
			id: uuidv4(),
			active: true,
		},
		{
			name: "02. 주는 여기 (Inhabit)",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/02.주는 여기 (Inhabit).wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "03. 내 길을 인도해 \n(Lead The Way)",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/03.내 길을 인도해 (Lead The Way).wav",
			color: ["#CD607D", "#c94043"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "04. 보혈, 어떤 말보다 (Better Word)",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/04.보혈, 어떤 말보다 (Better Word).wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "05. 예수로 물들다 (Message)",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/05. 예수로 물들다 (Message).wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "06. 예수가 보이네",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/06. 예수가 보이네.wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "07. 믿음 하나",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/07. 믿음 하나.wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "08. 나의 약함은 나의 자랑이요",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/08. 나의 약함은 나의 자랑이요.wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "09. 좋으신 하나님",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/09. 좋으신 하나님.wav",
			color: ["#EF8EA9", "#ab417f"],
			id: uuidv4(),
			active: false,
		},
		{
			name: "10. 은혜",
			cover:
				"asset/cover/cover.jpeg",
			artist: "Promise",
			audio: "/asset/songs/10. 은혜.wav",
			color: ["#CD607D", "#c94043"],
			id: uuidv4(),
			active: false,
		},

		//ADD MORE HERE
	];
}

export default chillHop;
